import { default as contactupLWW5VsuQMeta } from "/opt/buildhome/repo/pages/contact.vue?macro=true";
import { default as dashboard6cxkiptQlsMeta } from "/opt/buildhome/repo/pages/dashboard.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as personalz74hZFqs4fMeta } from "/opt/buildhome/repo/pages/personal.vue?macro=true";
import { default as privacy69l1Gm1VSzMeta } from "/opt/buildhome/repo/pages/privacy.vue?macro=true";
import { default as signinjIE1JUeO4jMeta } from "/opt/buildhome/repo/pages/signin.vue?macro=true";
import { default as terms_45of_45service4Xad5aMSOFMeta } from "/opt/buildhome/repo/pages/terms-of-service.vue?macro=true";
export default [
  {
    name: "contact",
    path: "/contact",
    component: () => import("/opt/buildhome/repo/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: dashboard6cxkiptQlsMeta || {},
    component: () => import("/opt/buildhome/repo/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "personal",
    path: "/personal",
    meta: personalz74hZFqs4fMeta || {},
    component: () => import("/opt/buildhome/repo/pages/personal.vue").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/opt/buildhome/repo/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "signin",
    path: "/signin",
    meta: signinjIE1JUeO4jMeta || {},
    component: () => import("/opt/buildhome/repo/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-service",
    path: "/terms-of-service",
    component: () => import("/opt/buildhome/repo/pages/terms-of-service.vue").then(m => m.default || m)
  }
]